<template>
    <section class="promo-section ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>
                            Loan Product features
                        </h2>
                        <p class="lead">
                            
                        </p>
                    </div>
                </div>
            </div>
            <div class="row equal">
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-timer text-white"></span>
                        </div>
                        <h5>Fast Approval</h5>
                        <p>
                            Get approved in 15minutes and real time discursment
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-agenda text-white"></span>
                        </div>
                        <h5>Minimal Documents Required</h5>
                        <p>
                            Only 1 valid ID and 1 month payslip
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-thumb-up text-white"></span>
                        </div>
                        <h5>Loan Amount</h5>
                        <p>
                            From 5000 to 50,000
                        </p>
                    </div>
                </div>
            </div>
               <div class="row equal mt-5">
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-calendar text-white"></span>
                        </div>
                        <h5>Flexibility</h5>
                        <p>
                            Flexible payment date your preferred salary payout date
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-face-smile text-white"></span>
                        </div>
                        <h5>Fixed Interest Rate</h5>
                        <p>
                            Fixed monthly interest rate
                        </p>
                    </div>
                </div>
       
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "Promo",
};
</script>