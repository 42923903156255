<template>
    <section
        class="hero-section background-img pt-100"
        style="
            background: url('img/hero-bg-4.png') no-repeat center center / cover;
        "
    >
        <div class="container" style='height : 800px'>
            <div class="row align-items-center justify-content-between">
                <div style='margin-top : 200px ;' class="col-md-12 col-lg-12">
                    <div 
                        class="mt-lg-0 mt-md-0 mt-5 text-white"
                    >
                        <h1 class="text-white">
                            <span>YiYi Sunflower Lending Company Inc.</span>
                        </h1>
                        <p class="lead mt-5">
                            Our team regularly evaluates-eveidence base impact studies in all key sector, and we've indentified these loan as heaving the as highest potential impact on borrower outcomes.
                        </p>

                    </div>
                </div>
                <!-- <div class="col-md-5 col-lg-5">
                    <submit-quote />
                </div> -->
            </div>
        </div>
      
    </section>
</template>

<script>

export default {
    name: "VideoPromo",
    components: {  },
    data: function () {
        return {
            showModal: false,
        };
    },
    methods: {
        showYouTubeModal: function (e) {
            e.preventDefault();
            this.showModal = true;
        },
    },
};
</script>

<style>
</style>