<template>
<v-container>
  <section
        id="contact"
        class="contact-us ptb-100"
        v-bind:class="isGray ? 'gray-light-bg' : ''"
    >
        <div class="container">
            <div class="row">
                <div
                    class="col-12 pb-3 message-box d-done"
                    v-bind:class="{
                        'd-none': !isSuccess && !hasError,
                        'd-block': isSuccess || hasError,
                    }"
                >
                    <div
                        class="alert"
                        v-bind:class="{
                            'alert-danger': hasError,
                            'alert-success': isSuccess,
                        }"
                    >
                        {{ alertText }}
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="section-heading">
                        <h3>Contact us</h3>
                      
                    </div>
                    <div class="footer-address">
                        <h6><strong>Head Office</strong></h6>
                        <p>One park drive Bldg 11
Drive corner 19 Ave BGC, Taguig</p>
                        <ul>
                            <li><span>Phone: 821-0711</span></li>
                            <li>
                                <span
                                    >Email :
                                    <a href="mailto:rasselle@yiyilending.ph"
                                        >rasselle@yiyilending.ph</a
                                    ></span
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-7">
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d965.4368363171978!2d121.05308118562982!3d14.556435613839023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c8f45583e559%3A0x2628816a4731720b!2sOne%20Park%20Drive!5e0!3m2!1sen!2sus!4v1642960447468!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
               
                </div>
            </div>
        </div>
    </section>
</v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>