<template>
    <div>
        <nav-bar />
        <div class="main">
            <banner />
            <Content />
            <site-footer />
        </div>
    </div>
</template>

<script>
import NavBar from "../views/commons/NavBar";
import Banner from "../views/allabout-page/Banner";
import Content from "../views/allabout-page/Content"
import SiteFooter from "../views/commons/SiteFooter";
export default {
    name: "DownloadPage",
    components: {
        NavBar,
        Banner,
        Content,
        SiteFooter
    },
};
</script>

<style>

</style>