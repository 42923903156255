<template>
<v-container>
     <section class=" ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading mb-5 text-center">
                        <h2>The Company</h2>
                        <p class="lead">
                            YiYi sunflower Lending Company Inc will be an outstanding lending firm that will be
attending to the enormous needs of salaried employees, OFW and students initially.
We offer a range of lending services and be the Right loan–with the Best rates, and
Terms to meet our prospective customer’s enormous needs.
                        </p>
                         <p class="lead">
                          Our major aim is to provide our customers with substantial loans at reasonable prices
and rates, while also keeping our customers Informed and active throughout the
process. YiYi Sunflower Lending Company Inc. will also strive to become friends and
advisers to our customers.
                        </p>
                         <p class="lead">
                       YiYi Sunflower Lending Company Inc is a good firm to work, with professional work
environment that is challenging, rewarding, innovative, and respectful of our
customers and employees. We provide excellent value to our customers and fair
reward to owners and employees.

                        </p>
                    </div>
                </div>
            </div>
       
        </div>
     </section>
</v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>