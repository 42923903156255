import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
// ------------ css ----------
import './assets/css/bootstrap.min.css';
import './assets/css/themify-icons.css';
import './assets/css/animate.min.css';
import './assets/css/main.css';
import './assets/css/responsive.css';
import VueFbCustomerChat from 'vue-fb-customer-chat'
import VueScrollTo from 'vue-scrollto';
import VueYoutube from 'vue-youtube';
import { gsap } from 'gsap';
import { CSSRulePlugin } from "gsap/CSSRulePlugin";

import jQuery from 'jquery';
window.$ = jQuery;

import 'jquery.mb.ytplayer';

Vue.use(VueFbCustomerChat, {
  page_id: 108603208252506, //  change 'null' to your Facebook Page ID,
  theme_color: '#59cabd', // theme color in HEX
  locale: 'en_US', // default 'en_US'
})

Vue.use(Vuetify)
Vue.use(VueScrollTo);
Vue.use(VueYoutube);

gsap.registerPlugin(CSSRulePlugin);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
