<template>
    <div>
        <nav-bar />
        <div class="main">
            <banner />
                <Clients />
            <site-footer />
        
        </div>
    </div>
</template>

<script>
import NavBar from "../views/commons/NavBar";
import Banner from "../views/partners-page/Banner";
import SiteFooter from "../views/commons/SiteFooter";
import Clients from "../views/commons/Clients";
export default {
    name: "DownloadPage",
    components: {
        NavBar,
        Banner,
        SiteFooter,
        Clients
    },
};
</script>

<style>

</style>