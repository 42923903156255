<template>
<v-container>
     <section class=" ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading mb-5 text-center">
                        <h2>Frequently Asked Questions</h2>
                        <p class="lead">
                            Find answer to all your question about our loans
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="single-faq">
                        <h5>What types of loans do you offer?</h5>
                        <p>
                            Salary Loan
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="single-faq">
                        <h5>Where are you located?</h5>
                        <p>
                           1412 One PArk Drive Building  11th st corner drive 9th ave BGc taguig
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="single-faq">
                        <h5>What is the minimum/ maximum loan period?</h5>
                        <p>
                            minimum is 15 days and maximum is 90 days
                        </p>
                    </div>
                </div>
  
            </div>
        </div>
    </section>
     <section class=" ptb-50">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading mb-5 text-center">
                        <h2>How to get loan?</h2>
                        <p class="lead">
                            Quick and transparent process
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                     <div style='text-align:center' class="single-faq">
                         <span style='font-size : 50px' class="ti-clipboard"></span>
                        <h5>Fill out application from online</h5>
                        <p>
                            Fill out the form completely
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                      <div style='text-align:center' class="single-faq">
                         <span style='font-size : 50px' class="ti-desktop"></span>
                        <h5>Get ready for a call</h5>
                        <p>
                           Prepare the required documents and we will inform you about your application through your mobile or email
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div style='text-align:center' class="single-faq">
                         <a style='font-size : 50px;' class="ti-thought"></a>
                        <h5>Wait for approval</h5>
                        <p>
                            We will inform you about your final approval condition
                        </p>
                    </div>
                </div>
    <div class="col-md-3">
                       <div style='text-align:center' class="single-faq">
                         <span style='font-size : 50px' class="ti-check"></span>
                        <h5>Get your approved loan</h5>
                        <p>
                          Get your approval loan
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
       <section class=" ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading mb-5 text-center">
                        <h2>How to qualify for a loan?</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="single-faq">
                        <h5>A Filipino citizen?</h5>
                        <p>
                            Only Filipino citizen are eligible to apply
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single-faq">
                        <h5>Age 18 to 68</h5>
                        <p>
                          Right productive age?
                          Apply today!
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single-faq">
                        <h5>A stable income</h5>
                        <p>
                            Eemployed (at least 3 momths), self employed, Businessman, pesioner, or receiving regular remittance.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
     <section class=" ptb-50">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading mb-5 text-center">
                        <h2>What Documents to bring?</h2>
                         <span style='font-size : 100px' class="ti-id-badge"></span>
                    </div>
                </div>
            </div>
            <div class="row">
                 <div class="col-md-1">
                    
                </div>
                <div class="col-md-2">
                    <div class="single-faq">
                        <h5>SSS</h5>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="single-faq">
                        <h5>Passport</h5>
                    </div>
                </div>
                <div class="col-md-2 ml-3">
                    <div class="single-faq">
                        <h5>Driver License</h5>
                        
                    </div>
                </div>
                <div class="col-md-2 ml-5">
                    <div class="single-faq">
                        <h5>Voters</h5>
                    
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="single-faq">
                        <h5>PRC</h5>
                       
                    </div>
                </div>
            </div>
        </div>
    </section>
     <section class=" ptb-50 mb-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading mb-5 text-center">
                        <h2>Secondary IDs</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="single-faq">
                        <li>Bank Account Statment</li>
                        <li>Birth Certificate/Clearance</li>
                        <li>Certificate of Employment</li>
                        <li>NBI Clearance</li>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="single-faq">
                           <li>Company ID</li>
                        <li>Electric Bill</li>
                        <li>Mobile Number</li>
                        <li>Water Bills</li>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="single-faq">
                       <li>Pag-ibig/HDMF ID</li>
                        <li>Philhealth ID</li>
                        <li>Phone Bill</li>
                        <li>Postal ID</li>
                    </div>
                </div>
                  <div class="col-md-3">
                    <div class="single-faq">
                        <li>Remittance Slip</li>
                        <li>Salary Slip</li>
                    </div>
                </div>
            </div>
        </div>
    </section>
</v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>