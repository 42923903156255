<template>
<v-container>
       <section class="team-single-section ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 col-sm-12 col-lg-5">
                    <div class="team-single-img">
                        <img
                            src="img/team-1.png"
                            alt="member"
                            class="img-fluid rounded shadow-sm"
                        />
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-lg-6">
                    <div class="team-single-text">
                        <div class="team-name mb-4">
                           <h1>Payment Guidlines</h1>
                            <span>All about payment guideline and early repayment</span>
                        </div>
                        <ul class="team-single-info">
                             <div class="d-flex align-items-start mb-5">
                                <div class="pr-4">
                                    <div
                                        class="icon icon-shape icon-color-1 rounded-circle"
                                    >
                                        <h3 class='mt-2'>1</h3>
                                    </div>
                                </div>
                                <div class="icon-text">
                                    <h5>Loan Account Number</h5>
                                    <p class="mb-0">
                                        loan account number as stated in the payment schedule shall be used as reference number.
                                    </p>
                                </div>
                            </div>
                             <div class="d-flex align-items-start mb-5">
                                <div class="pr-4">
                                    <div
                                        class="icon icon-shape icon-color-1 rounded-circle"
                                    >
                                        <h3 class='mt-2'>2</h3>
                                    </div>
                                </div>
                                <div class="icon-text">
                                    <h5>Transcation Fee</h5>
                                    <p class="mb-0">
                                        When making a payment in certain payment channel the customer shall pay applicable transcation fee.
                                    </p>
                                </div>
                            </div>
                             <div class="d-flex align-items-start mb-5">
                                <div class="pr-4">
                                    <div
                                        class="icon icon-shape icon-color-1 rounded-circle"
                                    >
                                        <h3 class='mt-2'>3</h3>
                                    </div>
                                </div>
                                <div class="icon-text">
                                    <h5>Check your payment schedule</h5>
                                    <p class="mb-0">
                                      Check payment schedule or other modes of payment not indicate in your payment schedule will not be accpeted.
                                    </p>
                                </div>
                            </div>
                             <div class="d-flex align-items-start mb-5">
                                <div class="pr-4">
                                    <div
                                        class="icon icon-shape icon-color-1 rounded-circle"
                                    >
                                        <h3 class='mt-2'>4</h3>
                                    </div>
                                </div>
                                <div class="icon-text">
                                    <h5>3 days ahead</h5>
                                    <p class="mb-0">
                                      To prevent any unneccessary delays and due to standard payment processing time it is recommended that payment are made 3 days before the due date.
                                    </p>
                                </div>
                            </div>
                                <div class="d-flex align-items-start mb-5">
                                <div class="pr-4">
                                    <div
                                        class="icon icon-shape icon-color-1 rounded-circle"
                                    >
                                        <h3 class='mt-2'>5</h3>
                                    </div>
                                </div>
                                <div class="icon-text">
                                    <h5>Retain receipt</h5>
                                    <p class="mb-0">
                                      Customer are advisted to retain receipts until payment for loaned amount is completed when paying to loan officer.
                                    </p>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
     
        </div>
    </section>
</v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>