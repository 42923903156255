<template>
    <div>
        <nav-bar />
        <div class="main">
            <banner />
            <promo />
            <features />
            <clients />
            <site-footer />
        </div>
    </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import Banner from "../../views/index-six/Banner";
import Promo from "../../views/index-one/Promo";
import Features from "../../views/index-six/Features";
import Clients from "../../views/commons/Clients";
import SiteFooter from "../../views/commons/SiteFooter";

export default {
    name: "IndexSix",
    components: {
        NavBar,
        Banner,
        Promo,
        Features,
        Clients,
        SiteFooter,
    },
};
</script>