<template>
    <footer class="footer-section">
        <div class="bg-footer-custom footer-top pt-150 pb-5 background-img-2">
            <div class="container">
                <div class="row justify-content-between">
                                        <div class="col-lg-4 ml-auto mb-4 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                                <div class="social-list-wrap">
                                <ul
                                    class="social-list list-inline list-unstyled"
                                >
                                    <li class="list-inline-item">
                                        <a
                                            href="#"
                                            target="_blank"
                                            title="Facebook"
                                            ><span class="ti-facebook"></span
                                        ></a>
                                    </li>
                                    &nbsp;
                                    <li class="list-inline-item">
                                        <a
                                            href="#"
                                            target="_blank"
                                            title="Twitter"
                                            ><span class="ti-instagram"></span
                                        ></a>
                                    </li>
                                    &nbsp;
                                    <li class="list-inline-item">
                                        <a
                                            href="#"
                                            target="_blank"
                                            title="Instagram"
                                            ><span class="ti-linkedin"></span
                                        ></a>
                                    </li>
                                    &nbsp;
                                    <li class="list-inline-item">
                                        <a
                                            href="#"
                                            target="_blank"
                                            title="printerst"
                                            ><span class="ti-youtube"></span
                                        ></a>
                                    </li>
                                </ul>
                            </div>
                            <ul class="list-unstyled support-list mt-3">
                                <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-location-pin mr-2"></span>
                                    One park drive Bldg 11 <br />Drive corner
                                    19 Ave BGC, Taguig
                                </li>
                                <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-mobile mr-2"></span>
                                    <a href="tel:+61283766284">
                                        821-0711</a
                                    >
                                </li>
                                <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-email mr-2"></span
                                    ><a href="mailto:rasselle@yiyilending.ph">
                                        rasselle@yiyilending.ph</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 ml-auto mb-4 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <h5 class="mb-3 text-white">Our Partners</h5>
                            <ul class="list-unstyled">
                                <li class="mb-2"><a href="partners">Partners</a></li>
                               
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 ml-auto mb-4 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <h5 class="mb-3 text-white">All About Loands</h5>
                            <ul class="list-unstyled">
                                <li class="mb-2"><a href="allaboutloans">All about loan</a></li>
                                <li class="mb-2"><a href="allaboutloans">How to get Loan</a></li>
                                <li class="mb-2"><a href="allaboutloans">What Documents Need</a></li>
                               
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <div class="footer-nav-wrap text-white">
                            <h5 class="mb-3 text-white">Payment</h5>
                           <ul class="list-unstyled">
                                <li class="mb-2"><a href="payment">Payments Guidelines</a></li>
                              
                            </ul>
                        </div>
                    </div>
                     <div class="col-lg-2">
                        <div class="footer-nav-wrap text-white">
                            <h5 class="mb-3 text-white">Careers</h5>
                           <ul class="list-unstyled">
                                <li class="mb-2"><a href="career">Join Us</a></li>
                              
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </footer>
</template>

<script>
export default {
    name: "SiteFooter",
};
</script>

<style scoped lang="scss">
.bg-footer-custom {
    background: url("../../assets/img/footer-bg.png") no-repeat center top /
        cover !important;
}
</style>