<template>
   <div style='min-height : 550px'>
    <section
        class="client-section ptb-100"
        v-bind:class="isGray ? 'gray-light-bg' : ''"
    >
        <div class="container">
            
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>Our Partners</h2>
                     
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-12">
                    <carousel
                        :items="12"
                        :nav="false"
                        :autoplay="true"
                        :dots="true"
                        :loop="true"
                        :margin="55"
                        slideTransition="linear"
                        :autoplayTimeout="4000"
                        :autoplaySpeed="false"
                        :smartSpeed="5000"
                        :responsive="{
                            0: { items: 1 },
                            500: { items: 3 },
                            600: { items: 4 },
                            800: { items: 5 },
                            1200: { items: 6 },
                        }"
                        class="owl-theme clients-carousel dot-indicator owl-loaded owl-drag"
                    >
                        <div class="item single-client">
                            <img
                                src="img/client-6-color.png"
                                alt="client logo"
                                class="client-img"
                            />
                        </div>
                        <div class="item single-client">
                            <img
                                src="img/client-3-color.png"
                                alt="client logo"
                                class="client-img"
                            />
                        </div>
                        <div class="item single-client">
                            <img
                                src="img/client-5-color.png"
                                alt="client logo"
                                class="client-img"
                            />
                        </div>

                        <div class="item single-client">
                            <img
                                src="img/client-1-color.png"
                                alt="client logo"
                                class="client-img"
                            />
                        </div>
                        <div class="item single-client">
                            <img
                                src="img/new1.png"
                                alt="client logo"
                                class="client-img"
                            />
                        </div>
                        <div class="item single-client">
                            <img
                                src="img/new2.png"
                                alt="client logo"
                                class="client-img"
                            />
                        </div>
                        <div class="item single-client">
                            <img
                                src="img/new3.png"
                                alt="client logo"
                                class="client-img"
                            />
                        </div>
                        <div class="item single-client">
                            <img
                                src="img/new4.png"
                                alt="client logo"
                                class="client-img"
                            />
                        </div>
                        <div class="item single-client">
                            <img
                                src="img/new5.png"
                                alt="client logo"
                                class="client-img"
                            />
                        </div>
                        
                    </carousel>
                </div>
            </div>
           
        </div>
    </section>
   </div>
    
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
    name: "Customer",
    components: { carousel },
    props: {
        isGray: {
            type: Boolean,
            default: false,
        },
        paddingTop: {
            type: Boolean,
            default: true,
        },
        paddingBottom: {
            type: Boolean,
            default: true,
        },
    },
};
</script>



<style>
</style>